body {
    margin: 20px 20px;
    font-family: sans-serif;
}

.todo-container {
    width: 300px;
    margin: auto;
    border: darkslategrey 1px solid;
    border-radius: 10px;
    box-shadow: gray 5px 5px 5px;
}

@media (min-width: 0px) and (max-width: 330px) {
    .todo-container {
        width: 250px;
    }
}

.input-container {
    text-align: center;
    margin-top: 20px;
}

.button-container {
    text-align: center;
    margin-top: 20px;
}