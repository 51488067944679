.todo-container > div {
    margin: 20px 0;
}

.todo-container > div::after {
    content: "";
    display: block;
    margin: 0 auto;
    width: 80%;
    padding-top: 20px;
    border-bottom: 1px solid black;
}

.todo-container > div > input {
    margin-left: 30px;
}

.text {
    margin-left: 5px;
    display: inline-flex;
    width: 220px;
}

@media (min-width: 0px) and (max-width: 330px) {
    .text {
        width: 170px;
    }
}

.todo-container > .last::after {
    border: none;
    padding: 0;
}

.complete {
    text-decoration: line-through;
    color: gray;
}

.todos-empty {
    text-align: center;
}

::selection {
    color: none;
    background: none;
}